







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import DiagnosticList from '@/components/diagnostic/DiagnosticList.vue';
import DiagnosticHeader from '@/components/diagnostic/DiagnosticHeader.vue';

@Component({
  components: {
    DiagnosticHeader,
    DiagnosticList,
  },
})
export default class DiagnosticView extends Vue {
}
