








































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { ENVIRONMENT_TYPES } from '../../helpers/Constants';
import OverlayLoader from '../shared/OverlayLoader.vue';
import { ComponentStatus } from '../../enums';
import { getStatusIcon, getStatusColour } from '../../helpers/Common';
import {
  ComponentModel,
  DiagnosticReportModel,
  ComponentReportModel,
  ConnectionReportModel,
} from '../../models';

@Component({
  components: {
    OverlayLoader,
  },
})
export default class DiagnosticList extends Vue {
  readonly items = [
    { key: 'API', value: 'API' },
    { key: 'Windows Service', value: 'WindowsService' },
    { key: 'Website', value: 'Website' },
  ];
  readonly environments = ENVIRONMENT_TYPES;
  selectedComponents: string[] = [];
  selectedEnvironment: string = '';
  isLoading: boolean = false;
  isLaunchClicked: boolean = false;
  showDiagnosticReport: boolean = false;

  get components(): ComponentModel[] {
    return this.$store.state.components.filter((x: ComponentModel) => x.type === 'API')
      .sort((a: ComponentModel, b: ComponentModel) => {
        return a.name < b.name
          ? -1
          : a.name > b.name
            ? 1
            : 0;
      });
  }

  get diagnostics(): DiagnosticReportModel {
    const diagnosticReport: DiagnosticReportModel = this.$store.state.diagnostics;
    if (diagnosticReport != null) {
      this.isLaunchClicked = false;
      diagnosticReport.components.sort((a: ComponentReportModel, b: ComponentReportModel) => {
        return a.name < b.name
          ? -1
          : a.name > b.name
            ? 1
            : 0;
      });
    }
    return diagnosticReport;
  }

  emptyResult(): void {
    this.$store.dispatch('deleteDiagnostics');
  }

  selectComponents(components: string[]): void {
    this.selectedComponents = [];
    this.selectedComponents = components;
  }

  selectEnvironment(envirnoment: string): void {
    this.selectedEnvironment = envirnoment;
  }

  getDiagnositcReports(): void {
    this.isLoading = true;
    this.isLaunchClicked = true;
    this.showDiagnosticReport = false;
    this.emptyResult();
    const payload = { env: this.selectedEnvironment, names: this.selectedComponents };
    this.$store.dispatch('loadDiagnostics', payload)
      .then(() => {
        this.isLoading = false;
        this.showDiagnosticReport = true;
      });
  }

  data() {
    return {
      rules: {
        required: (v: string) => !!v || 'Required.',
      },
    };
  }

  mounted(): void {
    this.isLoading = true;
    this.$store.dispatch('loadComponents')
      .then(() => {
        this.isLoading = false;
      });
  }

  getStatusColour(status: ComponentStatus): string {
    return getStatusColour(status);
  }

  getStatusIcon(status: ComponentStatus): string {
    return getStatusIcon(status);
  }

  isAllComponentsSelected() {
    return this.selectedComponents.length === this.components.map(x => x.name).length;
  }

  isSomeComponentsSelected() {
    return this.selectedComponents.length > 0 && !this.isAllComponentsSelected;
  }

  getIcon(): string {
    if (this.isAllComponentsSelected()) return 'mdi-close-box';
    if (this.isSomeComponentsSelected()) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  getStatusColorClass(percentage: number): string {
    if (percentage === 100) return 'green--text';
    if (percentage === 0) return 'red--text';
    return 'yellow--text';
  }

  getCompletedMessage(report: DiagnosticReportModel): string {
    return `Diagnostics's completed ${report.correctComponents} / ${report.totalComponents} components from ${report.requested} selected`;
  }

  iconCheck(connections: ConnectionReportModel[]): string {
    const isStatusDown = connections.some(x => x.status === ComponentStatus.Down);
    if (isStatusDown) {
      return 'mdi-close-circle';
    }
    return 'mdi-checkbox-marked-circle';
  }

  toggleSelectAll(): void {
    this.$nextTick(() => {
      if (this.isAllComponentsSelected()) {
        this.selectedComponents = [];
      } else {
        this.selectedComponents = this.components.map(x => x.name);
      }
    });
  }
}

